
import province from './provinceData.js'
import city from './cityData.js'
import area from './areaData.js'
// import street from './streetData.js'
var arrayToTree = require('array-to-tree');
export function getProvince(){
    return province
} 
export function getCity(){
    return city
}
export function getArea(){
    return area
}
export function getStreet(){
    return street
}

export function getCityTree(){
    let result = province
    city.map( c=> {
        c['pcode']  = c.provinceCode
        result.push(c)
    })
    
    return arrayToTree(result, {parentProperty:'pcode',customID: 'code'})
}
export function getAreaTree(){
    let result = province
    city.map( c=> {
        c['pcode']  = c.provinceCode
        result.push(c)
    })
    area.map( v=>{
        v['pcode']  = v.cityCode
        result.push(v)
    })
    return arrayToTree(result, {parentProperty:'pcode',customID: 'code'})
}
// export function getStreetTree(){
//     let result = province
//     city.map( c=> {
//         c['pcode']  = c.provinceCode
//         result.push(c)
//     })
//     area.map( v=>{
//         v['pcode']  = v.cityCode
//         result.push(v)
//     })
//     street.map( v=>{
//         v['pcode']  = v.areaCode
//         result.push(v)
//     })
//     return arrayToTree(result, {parentProperty:'pcode',customID: 'code'})
// }